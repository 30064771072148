<template>
  <div class='login_container' >
    <item-scroll ref="ItemScroll">
      <img class="login_banner" :src="require('@/assets/images/login/banner.png')" @load="initScroll" alt="">
      <div class="login_icon_block global_container_center">
        <div class="login_icon global_shadow global_primary_border_color">
          <img  :src="require('@/assets/images/login/icon.png')" @load="initScroll" alt="">
        </div>
      </div>
      <div class='login_main'>
        <div class="login_form">
          <div class='login_content'>
              <div class='login_block'>
                  <div class='login_block_key global_primary_text_color'>
                    <img  :src="require('@/assets/images/login/phone.png')" alt="">
                  </div>
                  <div class='login_block_value'>
                      <div class="login-clunmu-input">
                        <el-input placeholder="请输入手机号" v-model="data.phone" @input='checkPhone' maxlength='11' minlength='11'></el-input>
                      </div>
                  </div>
              </div>
              <div class='login_block'>
                  <div class='login_block_key global_primary_text_color'>
                    <img  :src="require('@/assets/images/login/lock.png')" alt="">
                  </div>
                  <div class='login_block_value'>
                      <div class="login-clunmu-input">
                        <el-input placeholder="请输入验证码" v-model="data.code" @input='checkCode' maxlength='5' minlength='5' >
                          <template #append>
                            <el-button type='primary' class="login-code" slot="append" @click.native='getCode' >{{timer.text}}</el-button>
                          </template>
                        </el-input>
                      </div>
                  </div>
              </div>
          </div>
          <div class='login_btn_group'>
              <el-button type='primary' @click.native='doLogin' round>登录</el-button>
          </div>
        </div>
      </div>
    </item-scroll>
  </div>
</template>

<script>
import ItemScroll from '@/components/unit/ItemScroll'
export default {
  name: 'Login',
  data () {
    return {
      data:{
        phone: '',
        code: ''
      },
      timer:{
        time:null,
        start_time:0,
        count:60,
        text:'获取验证码'
      },
      codeLoading:false
      
    }
  },
  components:{
    ItemScroll
  },
  created(){
    let data = this.$tools.getLocalCookie("adminLoginData")
    if(data){
      this.data = data
    }
  },
  methods:{
    checkTime(){
      if(this.timer.time){
        clearTimeout(this.timer.time);
        this.timer.time = null;
      }
      let now = Date.now();
      if(now > this.timer.start_time + this.timer.count * 1000 || this.timer.start_time == 0) {
        this.timer.start_time = 0;
        this.timer.text='获取验证码'
        this.codeLoading=false
        return;
      }
      this.timer.time = setTimeout(()=>{
        this.timer.text = `${this.timer.count - Math.floor((now - this.timer.start_time)/1000)}s`
        this.checkTime();
      },100)
    },
    initScroll(){
      this.$refs.ItemScroll.initScroll()
    },
    getCode(){
      if(this.codeLoading) return ;
      if(!(/^1\d{10}$/g.test(this.data.phone))){
        this.$tips.error({text:'请输入格式正确的手机号'})
        return false;
      }
      this.codeLoading = false;
      this.$axios.get(`${this.$urls.admin.code}?phone=${this.data.phone}`).then((res)=>{
        setTimeout(()=>{this.codeLoading=false},1000)
        this.timer.start_time = Date.now()
        this.checkTime();
      }).catch(error=>{
        setTimeout(()=>{this.codeLoading=false},1000)
      })
    },
    checkPhone(){
      this.data.phone = this.data.phone.replace(/\D/g,'')
    },
    checkCode(){
      this.data.code = this.data.code.replace(/\D/g,'')
    },
    formatData(){
      if(!(/^1\d{10}$/g.test(this.data.phone))){
        this.$tips.error({text:'请输入格式正确的手机号'})
        return false;
      }
      if(!(/^\d{5}$/g.test(this.data.code))){
        this.$tips.error({text:'请输入正确的验证码'})
        return false;
      }
      return true;
    },
    doLogin(){
      if(!this.formatData() || this.loading ) return;
      this.loading = true;
      this.$axios.post(this.$urls.admin.login,this.data).then((res)=>{
        setTimeout(()=>{ this.loading = false;},1000)
        // 存取，用于永久保存下次免输
        this.$tools.setCookie('phone',this.data.phone)
        // 用于确认登录状态
        this.$tools.setLocalCookie('adminLoginData',this.data)
        this.$router.push({
          path:'/adminmeet'
        })
      }).catch(err=>{
        setTimeout(()=>{ this.loading = false;},1000)
      })
    },
  }
}
</script>

<style>
.login_container{
    display:flex;
    flex-direction: column;
    height:100%;
    width:100%;
    position: relative;
    background:#fff;
    padding-bottom:4vw
}
.login_back{
    height:100%;
    width:100%;
    position: absolute;
    left:0;
    top:0;
    background:#f5f5f5;
    opacity: .2;
}
.login_main{
    position: relative;
    z-index:2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login_form{
  width:70%;
}
.login_block input{
    border:none;
    font-size:4vw;
    padding-left:0;
    padding-right:0;
}
.login_block_key{
    font-size:6vw;
    flex:12vw 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* color:#81304B; */
}
.login_block_key img{
  width:6vw;
  height:auto;
}
.login_block_value{
    flex:1 0 0;
}
.login_btn_group{
    width:100%;
    margin-top:6vw;
}
.login_btn_group button{
    width:100%;
}
.login_btn_group .el-input__icon{
    width:6vw !important;
    line-height: 20vw;;
}
.login_block {
    border-bottom:1px solid #f1f1f1;
    height:12vw;
    background:#fff;
    display:flex;
    justify-content:center;
    align-items:center;
}
.login_block:first-child{
    /* border-bottom:none; */
    /* border-top-left-radius: 1vw;
    border-top-right-radius: 1vw; */
}
.login_block:last-child{
    /* border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw; */
}
.login_title{
    font-size:6vw;
    height:6vw;
    display:flex;
    justify-content:center;
    align-items:flex-end;
    width:100%;
}
.login_title_second{
    font-size:5vw;
    height:18vw;
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
}
.login_container .el-input-group__append{
    border:none !important;
    background:none !important;
}
.login_container .login-clunmu-input{
    height:100%;
    width:100%;
    position: relative;
    padding:2vw 0
}
.login_container .login-clunmu-input input{
    height:5.6vw;
    line-height: 5.6vw;
    padding:0;
    margin:0;
}
.login_banner{
  width:100%;
  height:auto;
}
.login_icon_block{
  height:auto;
  padding:2vw 0;
}
.login_icon{
  border-width:1px;
  border-style:solid;
  border-radius:999vw;
  padding:1vw;
}
.login_icon img{
  width:12vw;
  height:auto;
}
.login-code span{
  display: inline-block;
  background: #ededed;
  padding: 1vw 2vw;
  border-radius:1vw;
}
</style>

